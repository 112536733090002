import { RouteConfig } from './types';

export const esRoutes: RouteConfig = {
  home: '/inicio',
  loginWithType: '/acceso/:userType',
  login: '/acceso',
  forgotPassword: '/contraseña-olvidada',
  changePassword: '/change-password',
  register: '/registro',
  patientRegister: '/paciente/registro',
  patientThankYou: '/paciente/gracias',
  hcpRegister: '/hcp/registro',
  hcpThankYou: '/hcp/gracias',
  profile: '/perfil',
  yourCondition: '/iniciando-el-recorrido/su-enfermedad',
  coachingTool: '/bienestar-y-apoyo/su-bienestar/herramientas-de-coaching',
  hcpMakeRequestRepCall: '/acceso-para-profesionales-de-la-salud/realizar-una-solicitud/solicitar-una-llamada-de-representante',
  hcpRequestRepCall: '/acceso-para-profesionales-de-la-salud/solicitar-una-llamada-de-representante',
  hcpOrderKitIdacio: '/acceso-para-profesionales-de-la-salud/realizar-una-solicitud/pedir-un-kit-idacio',
  hcpOrderKitTyenne: '/acceso-para-profesionales-de-la-salud/realizar-una-solicitud/pedir-un-kit-tyenne',
  hcpOrderKitOtulfi: '/acceso-para-profesionales-de-la-salud/realizar-una-solicitud/pedir-un-kit-otulfi',
  dynamic: '',
  hcpAccess: '/acceso-para-profesionales-de-la-salud',
 
  myTreatment: '/mi-tratamiento',  
 
  idacioUnderstanding: '/mi-idacio/comprender-el-tratamiento',
  idacioHowToInject: '/mi-idacio/inyección',
  idacioCaring: '/mi-idacio/cuidado-de-su-medicamento',
  idacioHelp: '/mi-idacio/ayuda-y-apoyo',
  idacioGoalsSettings: '/mi-idacio/mi-progreso/establecer-objetivos/configuración',
  idacioGoals: '/mi-idacio/mi-progreso/establecer-objetivos',
  idacioGoalsSettingsGoal: '/mi-idacio/mi-progreso/establecer-objetivos/configuración/objetivos',
  idacioLogMedicationSettings: '/mi-idacio/mi-progreso/seguimiento-del-tratamiento/configuración',
  idacioLogMedicationSettingsMedication: '/mi-idacio/mi-progreso/seguimiento-del-tratamiento/configuración/tratamiento',
  idacioReminders: '/mi-idacio/mi-progreso/recordatorios',
  idacioProgress: '/mi-idacio/mi-progreso',
  idacioCoolbagThankYou: '/mi-idacio/coolbag/gracias',
  idacioCoolbag: '/mi-idacio/coolbag',
  idacio: '/mi-idacio',
  

  tyenneUnderstanding: '/mi-tyenne/comprender-tyenne',
  tyenneHowToInject: '/mi-tyenne/inyección-tyenne',
  tyenneCaring: '/mi-tyenne/cuidado-de-su-tyenne',
  tyenneHelp: '/mi-tyenne/ayuda-y-apoyo',
  tyenneGoals: '/mi-tyenne/mi-progreso/establecer-objetivos',
  tyenneGoalsSettings: '/mi-tyenne/mi-progreso/establecer-objetivos/configuración',
  tyenneGoalsSettingsGoal: '/mi-tyenne/mi-progreso/establecer-objetivos/configuración/objetivos',
  tyenneLogMedication: '/mi-tyenne/mi-progreso/seguimiento-del-tratamiento/configuración',
  tyenneLogMedicationSettingsMedication: '/mi-tyenne/mi-progreso/seguimiento-del-tratamiento/configuración/tratamiento',
  tyenneReminders: '/mi-tyenne/mi-progreso/recordatorios',
  tyenneProgress: '/mi-tyenne/mi-progreso',
  tyenneCoolbagThankYou: '/mi-tyenne/request-a-coolbag/gracias',
  tyenneCoolbag: '/mi-tyenne/coolbag',
  tyenne: '/mi-tyenne',
  
  otulfiUnderstanding: '/mi-otulfi/comprender-el-tratamiento-otulfi',
  otulfiHowToInject: '/mi-otulfi/inyección-otulfi',
  otulfiCaring: '/mi-otulfi/cuidado-de-su-medicamento-otulfi',
  otulfiHelp: '/mi-otulfi/ayuda-y-apoyo-otulfi',
  otulfiGoalsSettings: '/mi-otulfi/mi-progreso/establecer-objetivos/configuración',
  otulfiGoals: '/mi-otulfi/mi-progreso/establecer-objetivos',
  otulfiGoalsSettingsGoal: '/mi-otulfi/mi-progreso/establecer-objetivos/configuración/objetivos',
  otulfiLogMedicationSettings: '/mi-otulfi/mi-progreso/seguimiento-del-tratamiento/configuración',
  otulfiLogMedicationSettingsMedication: '/mi-otulfi/mi-progreso/seguimiento-del-tratamiento/configuración/tratamiento',
  otulfiReminders: '/mi-otulfi/mi-progreso/recordatorios',
  otulfiProgress: '/mi-otulfi/mi-progreso',
  otulfiCoolbagThankYou: '/mi-otulfi/coolbag/gracias',
  otulfiCoolbag: '/mi-otulfi/coolbag',
  otulfi: '/mi-otulfi',  
};