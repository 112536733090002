import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@material-ui/core";  // Add this import
import { getRoute } from "../../routing/language_support";
import { Api } from "../../api/Api";
import "../../assets/scss/Login/Login.scss";
import Footer from "../../component/Footer/Footer";
import { ATTEMPT_LOGIN } from "../../constants/constants";
import { GAEvent, GAEventCategory } from "../../interfaces/GAEvent";
import { PageType } from "../../interfaces/Pages";
import LoginSideImageGeneral from "../../resources/images/auth/login_side_image.jpg";
import LoginSideImagePatient from "../../resources/images/auth/login_side_image_patient.jpg";
import LoginSideImageHCP from "../../resources/images/auth/login_side_image_hcp.jpg";
import { getCurrentUser, login } from "../../store/AuthActions";
import { getContent } from "../../store/PageContentActions";
import { authState } from "../../store/Reducer";
import {
  TOOLBOX_FETCH_REQUESTED,
  TOOLBOX_RESPONSE_UPDATE,
} from "../../store/ToolBoxActions";
import { createGAEvent } from "../../util/util";
import LoginForm from "./LoginForm";
import WithLoader from '../../component/WithLoaderLogin';

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userType } = useParams();
  const auth = useSelector(authState);
  const [loginStatus, setLoginStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  
  let loginSideImage = LoginSideImageGeneral;
  if (userType === "patient") {
    loginSideImage = LoginSideImagePatient;
  } else if (userType === "hcp") {
    loginSideImage = LoginSideImageHCP;
  }

  useEffect(() => {
    dispatch(getContent(PageType.login));
  
    // Only redirect if authenticated and not in the process of logging in
    if (auth?.authenticated && !userType && !isLoggingIn) {
      history.push("/" + PageType.home);
    }
  }, [dispatch, auth?.authenticated, userType, isLoggingIn, history]);

  const onLogin = async (data: any) => {
    setLoginStatus('loading');
    setIsLoggingIn(true);
    createGAEvent(GAEvent.FORM_SUBMISSION, GAEventCategory.LOGIN);

    /*
    if (!data.field_remember_me) {
      window.addEventListener("beforeunload", function (e) {
        e.preventDefault();

        if (PerformanceNavigationTiming.prototype.type === "reload") {
          return;
        }

        sessionStorage.clear();
        localStorage.clear();
        dispatch(logout(""));
      });
    }
    */

    if (auth?.authenticated) {
      await Api.logout().catch((error) => {});
    }
    
    try {
      const result = await Api.login(data);
      dispatch(login({ ...result, email: data.name }));
      dispatch(getContent(PageType.profile));
      dispatch({ type: TOOLBOX_FETCH_REQUESTED });
      dispatch(getCurrentUser());
      
      // Delay redirect and loading finish
      setTimeout(() => {
          setLoginStatus('success');
          setIsLoggingIn(false);
          history.push(getRoute('home'));
      }, 1000);
    } catch (error) {
      setLoginStatus('error');
      setIsLoggingIn(false);
      dispatch({ type: TOOLBOX_RESPONSE_UPDATE, error: error.message });
      
    }
  };

  return (
    <WithLoader controlLoading={true} loading={loginStatus === 'loading'}>
      <Grid container spacing={0} className="login-page">
        <Grid item md={12} lg={5} className="login-form">
          <LoginForm onSubmit={onLogin} />
          <div className="login-image-container">
            <img src={loginSideImage} alt="" />
          </div>
        </Grid>
      </Grid>
      <Footer />
    </WithLoader>
  );
};

export default Login;