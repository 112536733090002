import { Button } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { getRoute } from "../../routing/language_support";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useHistory, useLocation } from "react-router-dom";
import "../../assets/scss/Nav/Nav.scss";
import {
  HCP,
  hcpRestricted,
  /*homePage,*/ idacio,
  patient,
  patientRestricted, 
  tyenne, 
  otulfi,
} from "../../constants/constants";
import { NavMenu } from "../../interfaces/NavMenu";
import { /*PageContents,*/ PageType } from "../../interfaces/Pages";
import accountIcon from "../../resources/images/nav/profile.png";
import logoPng from "../../resources/logo/png/kabicare.png";
import logo from "../../resources/logo/webp/kabicare.webp";
import { AuthLink } from "../../routing/AuthLink";
import { LOGOUT } from "../../store/AuthActions";
import { authState, contentState } from "../../store/Reducer";
import { useQuery } from "../../util/util";
import { InverseButton } from "../InverseButton";
import HamburgerMenu from "./HamburgerMenu";
import MobileMenu from "./MobileNav";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const location = useLocation();
  let mobile = useQuery().get("mobile") === "true";
  const contents: {} | Object = useSelector(contentState);
  const currentPage = contents[location.pathname.substr(1)];
  const navMenu =
    currentPage?.menu && Object.keys(currentPage?.menu).length !== 0
      ? currentPage?.menu
      : contents[PageType.homeOld]?.menu;
  const auth = useSelector(authState);
  const dispatch = useDispatch();
  const history = useHistory();
  const userRoles = auth.roles;
  
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [mobileMenuClass, setMobileMenuClass] = useState("");
  const [/*menuItemWidths*/, collectMenuItemWidths] = useState<any[]>([]);

  const isHome = location.pathname === getRoute('home');
  const ref = useRef<HTMLDivElement | null>(null);

  const menu = { ...navMenu };

  const { t } = useTranslation();

  const hcpRegistrationAllowed = process.env.REACT_APP_SETTING_HCP_REGISTRATION_ALLOWED === 'true';

  useEffect(() => {
    if (ref.current) {
      const collection = ref.current.children;
      const arrCollection = [].slice.call(collection);
      const widths = arrCollection
        .filter((e: HTMLDivElement) => e.classList.contains("level-1"))
        .map((element: HTMLDivElement) => element.offsetWidth);
      collectMenuItemWidths(widths);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (mobile) return <></>;

  const handleMobileMenuClose = () => {
    setMobileMenuOpen(false);
    setMobileMenuClass("");
  };

  const handleMobileMenuOpen = () => {
    setMobileMenuOpen(true);
    setMobileMenuClass("menu-open");
  };

  const handleMobileMenu = () => {
    if (isMobileMenuOpen) {
      handleMobileMenuClose();
    } else {
      handleMobileMenuOpen();
    }
  };

  const handleLogin = () => {
    handleAuthAction("login");
  };

  const handleRegister = () => {
    handleAuthAction('register');
  };

  const handleAuthAction = (path) => {
    handleMobileMenuClose();
    history.push(getRoute(path));
  };

  const handleLogout = () => {
    handleMobileMenuClose();
    // Remove HCA-related items from local storage
    Object.keys(localStorage).forEach(key => {
      if (
        key.includes('-b2c_1a_hca_signup_signin') && 
        key.includes('-auth.onekeyconnect.com-')
      ) {
        localStorage.removeItem(key);
      }
    });
  
    // Call HCA SDK logout if available
    if (typeof hcaSdk !== 'undefined' && typeof hcaSdk.signOut === 'function') {
      hcaSdk.signOut();
    }    
      
    dispatch({ type: LOGOUT });
    history.push(getRoute(home));
  };

  const handleLevelToggle = (id: string) => {
    const div = document.getElementsByClassName(id)[0];
    const a = div.getElementsByTagName("a")[0];

    const classes = div.classList;

    if (classes.contains("open")) {
      classes.add("closing");
      classes.remove("open");
      a.classList.remove("active");

      const timer = setTimeout(() => {
        classes.remove("closing");
      }, 500);
      return () => clearTimeout(timer);
    } else if (classes.contains("closing")) {
      return;
    } else {
      classes.add("open");
      a.classList.add("active");
    }
  };

  const checkRoles = (roles) => {
    //////////////////////////////////////////////////////////////////////////////
    //////// GUY: CHANGES TO THIS LOGIC AS IT DIDN'T ALLOW FOR THE "NO ROLE" NAV ITEMS TO BE SHOWN
    if (!roles || roles.length === 0 || roles.includes("")) {
      return true;
    } else if (roles.includes(patient) && userRoles?.includes(patient)) {
      return true;
    } else if (roles.includes(HCP) && userRoles?.includes(HCP)) {
      return true;
    } else if (roles.includes(idacio) && userRoles?.includes(idacio)) {
      return true;
    } else if (roles.includes(tyenne) && userRoles?.includes(tyenne)) {
      return true;
    } else if (roles.includes(otulfi) && userRoles?.includes(otulfi)) {
      return true;      
    } else if (
      roles.includes(hcpRestricted) ||
      roles.includes(patientRestricted)
    ) {
      return true;
    }

    return false;
  };

  const hasVisibleChildren = (children: any) => {
    return (
      Object.values(children).filter((c: any) => checkRoles(c.roles)).length !== 0
    );
  };

  const renderMenu = (menu: NavMenu, renderLevel: number) => {
    return (
      menu &&
      Object.entries(menu).map(([key, menuItem], index) => {
        if ((menuItem?.roles as any)?.content) {
          menuItem.roles = [""];
        }
  
        if (!checkRoles(menuItem.roles)) {
          return null;
        }
        
        const classFromLabel = menuItem.label
          .toLowerCase()
          .replaceAll(" ", "-")
          .replaceAll("&", "and")
          .concat("-menu-item");
          
        const showTriangle = menuItem.field_include_triangle !== undefined && 
                     (menuItem.field_include_triangle === true || menuItem.field_include_triangle === "1");  
          
        return (
          <div
            key={`${key}-${index}`}
            className={`${renderLevel === 1 ? "level-" + renderLevel : ""} ${
              menuItem.child && renderLevel !== 1 ? "label" : ""
            } ${
              menuItem.child && hasVisibleChildren(menuItem.child)
                ? ["has-children", classFromLabel].join(" ")
                : classFromLabel
            }`}>
            
            <div className={`${renderLevel !== 1 ? "parent-level" : ""}`}>
            <AuthLink
              to={decodeURIComponent(decodeURIComponent(menuItem.url))}
              roles={menuItem.roles}
              onClose={() => {}}
              className={`${
                location.pathname.includes(
                  menuItem.url.replaceAll("%252f", "/")
                )
                  ? "active"
                  : ""
              } ${showTriangle ? "has-triangle" : ""}`}
            >
              {menuItem.label}
            </AuthLink>
  
              {menuItem.child && hasVisibleChildren(menuItem.child) && renderLevel !== 1 && (
                <div className={`children-indicator-container-${renderLevel}`}
                     onClick={() => handleLevelToggle(classFromLabel)}>
                  <div className={`children-indicator-${renderLevel}`}>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              )}
            </div>
  
            {menuItem.child && hasVisibleChildren(menuItem.child) && (
              <>
                {renderLevel === 1 && (
                  <>
                    <div className="children-indicator">
                      <span></span>
                      <span></span>
                    </div>
                  </>
                )}
  
                <div className={`level-${renderLevel + 1}`}>
                  {renderMenu(menuItem.child, renderLevel + 1)}
                </div>
              </>
            )}
          </div>
        );
      })
    );
  };

  let headerClasses = "header";
  headerClasses += isHome ? " home" : "";
  headerClasses += mobileMenuClass ? ` ${mobileMenuClass}` : "";

  return (
    <div className={headerClasses}>
      <div className="side-container">
        {/*logo*/}
        <div className="left-side">
          <RouterLink to="/">
            {
              <picture>
                <source
                    media="(min-width:1280px)"
                    srcSet={logo}
                    type="image/webp"
                />
                <source
                    media="(min-width:1280px)"
                    srcSet={logoPng}
                    type="image/jpeg"
                />
                <source srcSet={logo} type="image/webp"/>
                <img src={logoPng} alt="logo" width="178px" height="45px"/>
              </picture>
            }
          </RouterLink>
        </div>

        {/*nav items*/}
        <div className="middle" ref={ref}>
          <div className="menu-element-levels">{renderMenu(menu, 1)}</div>

          {!auth.authenticated &&
              <div className="menu-element-levels">
                <div className="level-1">
                  <AuthLink
                      to={getRoute('myTreatment')}
                      roles={["patient-restricted"]}
                      onClose={() => {
                      }}
                  >
                    {t("nav.myTreatment")}
                  </AuthLink>
                </div>
                
                {hcpRegistrationAllowed && 
                <div className="level-1">
                  <AuthLink
                      to={getRoute('hcpAccess')}
                      roles={["hcp-restricted"]}
                      onClose={() => {
                      }}
                  >
                    {t("nav.hcpAccess")}
                  </AuthLink>
                </div>
                }
              </div>
          }
        </div>

        {/*buttons*/}
        <div className="auth-button-container">
          {auth.authenticated ? (
              <>
                <div className="account-button-container">
                  <RouterLink to={getRoute('profile')}>
                    <Button
                        className="account-button auth-button MuiButton-textPrimary"
                        aria-label="account-button"
                    >
                      <img
                          src={accountIcon}
                          alt="account"
                          height="27"
                          width="21"
                      />
                    </Button>
                  </RouterLink>
                </div>
                <InverseButton
                    onClick={handleLogout}
                    className="logout-button auth-button MuiButton-textPrimary "
                    aria-label="logout"
                >
                  {t("nav.button.logout.label")}
                </InverseButton>
              </>
          ) : (
              <>
                <InverseButton
                    onClick={handleLogin}
                    className={"auth-button login-button MuiButton-textPrimary"}
                    aria-label="login"
                >
                  {t("nav.button.login.label")}
                </InverseButton>
                <Button
                    onClick={handleRegister}
                    className={"auth-button register-button MuiButton-textPrimary"}
                    aria-label="login"
                    style={{ display: 'none' }}
                >
                  {t("nav.button.register.label")}
                </Button>
              </>
          )}
        </div>
      </div>

      {isMobileMenuOpen && (
          <MobileMenu
              authenticated={auth.authenticated}
              menu={menu}
              onClose={handleMobileMenuClose}
              onLogin={handleLogin}
              onLogout={handleLogout}
              onRegister={handleRegister}
              isOpen={isMobileMenuOpen}
              logoutLabel={t("nav.button.logout.label")}/>
      )}
      <HamburgerMenu onOpen={handleMobileMenu}/>
    </div>
  );
}
