import { RouteConfig } from './types';

export const nlRoutes: RouteConfig = {
  home: '/home',
  loginWithType: '/login/:userType', // Change
  login: '/login',
  forgotPassword: '/wachtwoord-vergeten', // Change
  changePassword: '/change-password', // Change
  register: '/registreer', // Change
  patientRegister: '/patiënt/registreer', // Change
  patientThankYou: '/patiënt/bedankt', // Change
  hcpRegister: '/hcp/registreer', // Change
  hcpThankYou: '/hcp/bedankt', // Change
  profile: '/profiel', // Change
  yourCondition: '/aan-de-slag/uw-aandoening', // Change
  coachingTool: '/welzijn-en-ondersteuning/uw-welzijn/coaching-tools', // Change
  
  hcpMakeRequestRepCall: '/toegang-voor-zorgverleners/navraag/verzoek-een-vertegenwoordiger-oproep', // Change
  hcpRequestRepCall: '/toegang-voor-zorgverleners/verzoek-een-vertegenwoordiger-oproep', // Change
  hcpOrderKitIdacio: '/toegang-voor-zorgverleners/navraag/bestel-een-kit-idacio', // Change
  hcpOrderKitTyenne: '/toegang-voor-zorgverleners/navraag/bestel-een-kit-tyenne', // Change
  hcpOrderKitOtulfi: '/toegang-voor-zorgverleners/navraag/bestel-een-kit-otulfi',
  dynamic: '',
  hcpAccess: '/toegang-voor-zorgverleners', // Change
  myTreatment: '/mijn-behandeling', // Ensure that this is different
  
  idacioUnderstanding: '/mijn-idacio/inzicht-in-de-behandeling',
  idacioHowToInject: '/mijn-idacio/injecteren-van-idacio',
  idacioCaring: '/mijn-idacio/zorgen-voor-uw-medicatie',
  idacioHelp: '/mijn-idacio/hulp-en-ondersteuning',
  idacioGoalsSettings: '/mijn-idacio/mijn-voortgang/doelen/instellingen',
  idacioGoals: '/mijn-idacio/mijn-voortgang/doelen',
  idacioGoalsSettingsGoal: '/mijn-idacio/mijn-voortgang/doelen/instellingen/doel',
  idacioLogMedicationSettings: '/mijn-idacio/mijn-voortgang/medicatie-volgen/instellingen',
  idacioLogMedicationSettingsMedication: '/mijn-idacio/mijn-voortgang/medicatie-volgen/instellingen/medicatie',
  idacioReminders: '/mijn-idacio/mijn-voortgang/herinneringen',
  idacioProgress: '/mijn-idacio/mijn-voortgang',
  idacioCoolbagThankYou: '/mijn-idacio/coolbag/bedankt',
  idacioCoolbag: '/mijn-idacio/coolbag',
  idacio: '/mijn-idacio',
  
  tyenneUnderstanding: '/mijn-tyenne/inzicht-in-de-behandeling-tyenne',
  tyenneHowToInject: '/mijn-tyenne/injecteren-van-tyenne',
  tyenneCaring: '/mijn-tyenne/zorgen-voor-uw-tyenne',
  tyenneHelp: '/mijn-tyenne/hulp-en-ondersteunings',
  tyenneGoals: '/mijn-tyenne/mijn-voortgang/doelen',
  tyenneGoalsSettings: '/mijn-tyenne/mijn-voortgang/doelen/instellingen',
  tyenneGoalsSettingsGoal: '/mijn-tyenne/mijn-voortgang/doelen/instellingen/doel',
  tyenneLogMedication: '/mijn-tyenne/mijn-voortgang/medicatie-volgen/instellingen',
  tyenneLogMedicationSettingsMedication: '/mijn-tyenne/mijn-voortgang/medicatie-volgen/instellingen/medikamenten',
  tyenneReminders: '/mijn-tyenne/mijn-voortgang/herinneringen',
  tyenneProgress: '/mijn-tyenne/mijn-voortgang',
  tyenneCoolbagThankYou: '/mijn-tyenne/coolbag/bedankt',
  tyenneCoolbag: '/mijn-tyenne/coolbag',
  tyenne: '/mijn-tyenne',
  
  otulfiUnderstanding: '/mijn-otulfi/inzicht-in-de-behandeling-otulfi',
  otulfiHowToInject: '/mijn-otulfi/injecteren-van-otulfi',
  otulfiCaring: '/mijn-otulfi/zorgen-voor-uw-medicatie-otulfi',
  otulfiHelp: '/mijn-otulfi/hulp-en-ondersteuning-otulfi',
  otulfiGoalsSettings: '/mijn-otulfi/mijn-voortgang/doelen/instellingen',
  otulfiGoals: '/mijn-otulfi/mijn-voortgang/doelen',
  otulfiGoalsSettingsGoal: '/mijn-otulfi/mijn-voortgang/doelen/instellingen/doel',
  otulfiLogMedicationSettings: '/mijn-otulfi/mijn-voortgang/medicatie-volgen/instellingen',
  otulfiLogMedicationSettingsMedication: '/mijn-otulfi/mijn-voortgang/medicatie-volgen/instellingen/medicatie',
  otulfiReminders: '/mijn-otulfi/mijn-voortgang/herinneringen',
  otulfiProgress: '/mijn-otulfi/mijn-voortgang',
  otulfiCoolbagThankYou: '/mijn-otulfi/coolbag/bedankt',
  otulfiCoolbag: '/mijn-otulfi/coolbag',
  otulfi: '/mijn-otulfi',  
};