/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getRoute } from "../../routing/language_support";
import Popup from "../Popup";

interface AuthLinkProps {
  className?: string;
}

export const RestrictedPagePopup = ({
  className = "",
}: AuthLinkProps) => {
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();
  
  const idacioAllowed = process.env.REACT_APP_IDACIO_ALLOWED === 'true'
  const tyenneAllowed = process.env.REACT_APP_TYENNE_ALLOWED === 'true'  
  const otulfiAllowed = process.env.REACT_APP_OTULFI_ALLOWED === 'true'  
  
  const getContentText = () => {
    if (idacioAllowed && tyenneAllowed && otulfiAllowed) {
      return t("restrictedPopup.content");
    } else if (idacioAllowed) {
      return t("restrictedPopup.contentIdacio");
    } else if (tyenneAllowed) {
      return t("restrictedPopup.contentTyenne");
    } else if (otulfiAllowed) {
      return t("restrictedPopup.contentOtulfi");
    } else {
      return t("restrictedPopup.content");
    }
  };
  
  return (
      <Popup
        open={open}
        onButton1={() => {
          history.push(getRoute('home'));
          setOpen(false);
        }}        
        onButton2={() => {
          setOpen(false);
          history.push(getRoute('login'));
        }}
        className={"restricted-popup"}
        title={t("restrictedPopup.title")}
        contentText={getContentText()}
        button1Text={t("restrictedPopup.button.cancel.label")}
        button2Text={t("restrictedPopup.button.submit.label")}
      />
  );
};
