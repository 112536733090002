import { RouteConfig } from './types';

export const enRoutes: RouteConfig = {
  home: '/home',
  loginWithType: '/login/:userType',
  login: '/login',
  forgotPassword: '/forgot-password',
  changePassword: '/change-password',
  register: '/register',
  patientRegister: '/patient/register',
  patientThankYou: '/patient/thank-you',
  hcpRegister: '/hcp/register',
  hcpThankYou: '/hcp/thank-you',
  profile: '/profile',
  yourCondition: '/getting-started/your-condition',
  coachingTool: '/wellbeing-and-support/your-wellbeing/coaching-tool',
  hcpMakeRequestRepCall: '/hcp-access/make-a-request/request-a-rep-call',
  hcpRequestRepCall: '/hcp-access/request-a-rep-call',
  hcpOrderKitIdacio: '/hcp-access/make-a-request/order-a-kit-idacio',
  hcpOrderKitTyenne: '/hcp-access/make-a-request/order-a-kit-tyenne',
  hcpOrderKitOtulfi: '/hcp-access/make-a-request/order-a-kit-otulfi',
  dynamic: '',
  hcpAccess: '/hcp-access',
  myTreatment: '/my-treatment',
  
  idacioUnderstanding: '/my-idacio/understanding-treatment',
  idacioHowToInject: '/my-idacio/how-to-inject-idacio',
  idacioCaring: '/my-idacio/caring-for-your-medication',
  idacioHelp: '/my-idacio/help-and-support',
  idacioGoalsSettings: '/my-idacio/my-progress/goals/settings',
  idacioGoals: '/my-idacio/my-progress/goals',
  idacioGoalsSettingsGoal: '/my-idacio/my-progress/goals/settings/goal',
  idacioLogMedicationSettings: '/my-idacio/my-progress/log-medication/settings',
  idacioLogMedicationSettingsMedication: '/my-idacio/my-progress/log-medication/settings/medication',
  idacioReminders: '/my-idacio/my-progress/reminders',
  idacioProgress: '/my-idacio/my-progress',
  idacioCoolbagThankYou: '/my-idacio/coolbag/thank-you',
  idacioCoolbag: '/my-idacio/coolbag',
  idacio: '/my-idacio',
  
  tyenneUnderstanding: '/my-tyenne/understanding-tyenne',
  tyenneHowToInject: '/my-tyenne/how-to-inject-tyenne',
  tyenneCaring: '/my-tyenne/caring-for-your-tyenne',
  tyenneHelp: '/my-tyenne/help-and-supports',
  tyenneGoals: '/my-tyenne/my-progress/goals',
  tyenneGoalsSettings: '/my-tyenne/my-progress/goals/settings',
  tyenneGoalsSettingsGoal: '/my-tyenne/my-progress/goals/settings/goal',
  tyenneLogMedication: '/my-tyenne/my-progress/log-medication/settings',
  tyenneLogMedicationSettingsMedication: '/my-tyenne/my-progress/log-medication/settings/medication',
  tyenneReminders: '/my-tyenne/my-progress/reminders',
  tyenneProgress: '/my-tyenne/my-progress',
  tyenneCoolbagThankYou: '/my-tyenne/request-a-coolbag/thank-you',
  tyenneCoolbag: '/my-tyenne/request-a-coolbag',
  tyenne: '/my-tyenne',
  
  otulfiUnderstanding: '/my-otulfi/understanding-treatment-otulfi',
  otulfiHowToInject: '/my-otulfi/how-to-inject-otulfi',
  otulfiCaring: '/my-otulfi/caring-for-your-medication-otulfi',
  otulfiHelp: '/my-otulfi/help-and-support-otulfi',
  otulfiGoalsSettings: '/my-otulfi/my-progress/goals/settings',
  otulfiGoals: '/my-otulfi/my-progress/goals',
  otulfiGoalsSettingsGoal: '/my-otulfi/my-progress/goals/settings/goal',
  otulfiLogMedicationSettings: '/my-otulfi/my-progress/log-medication/settings',
  otulfiLogMedicationSettingsMedication: '/my-otulfi/my-progress/log-medication/settings/medication',
  otulfiReminders: '/my-otulfi/my-progress/reminders',
  otulfiProgress: '/my-otulfi/my-progress',
  otulfiCoolbagThankYou: '/my-otulfi/coolbag/thank-you',
  otulfiCoolbag: '/my-otulfi/coolbag',
  otulfi: '/my-otulfi',  
};