/* eslint-disable @typescript-eslint/no-array-constructor */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import "../../assets/scss/Home/Home.scss";
import DynamicFooter from "../../component/Footer/Footer";
import { ContentPageContainer } from "../../component/PageContainer/ContentPageContainer";
import { HomePageType } from "../../interfaces/HomePageType";
import { CarouselItem, PageType } from "../../interfaces/Pages";
import { contentState, authState } from "../../store/Reducer"; 
import { LowerHomePageSection } from "./LowerHomePageSection";

export const HomePage = () => {
  const content: HomePageType = useSelector(contentState)[PageType.home];
  const auth = useSelector(authState);
  const [heroContent, setHeroContent] = useState<CarouselItem>();

  const [inactiveCard, setInactiveCard] = useState<CarouselItem>();

  const [carouselCards, setCarouselCards] = useState(new Array());

useEffect(() => {

  // @ts-ignore
  if (!content) {
    return;
  }
  if (!inactiveCard) {
    let carousel = [...content.sections[0].carousel_items];
    console.log("carousel: " + JSON.stringify(carousel));
    console.log("auth roles: " + JSON.stringify(auth.roles));
    
    // Handle different formats of auth.roles
    let userRoles: string[] = [];
    if (Array.isArray(auth.roles) && auth.roles[0] === "") {
      // User is logged out or has no roles
      userRoles = [];
    } else if (typeof auth.roles === 'string') {
      // User has roles
      userRoles = auth.roles.split(',').map(role => role.trim());
    }
    
    // Filter carousel items based on authentication and role restrictions
    carousel = carousel.filter((item) => {
      console.log("uth.authenticated: " + JSON.stringify(auth));
      // Check hide_if_authenticated first (this takes priority)
      if (auth.authenticated && item.field_hide_if_authenticated === true) {
        return false;
      }
      
      // Check role restrictions
      if (item.field_restricted_to_role && item.field_restricted_to_role.length > 0) {
        // If user has no roles, hide items with role restrictions
        if (userRoles.length === 0) {
          return false;
        }
        
        // Convert field_restricted_to_role to array if it's a string
        const restrictedRoles = Array.isArray(item.field_restricted_to_role) 
          ? item.field_restricted_to_role 
          : [item.field_restricted_to_role];
        
        // Check if any user role matches any restricted role
        return userRoles.some(userRole => 
          restrictedRoles.includes(userRole)
        );
      }
      
      // If no restrictions or empty restrictions, include the item
      return true;
    }); 
    
    const inActive = carousel.splice(0, 1)[0];
    setCarouselCards(carousel);
    setHeroContent(inActive);
    setInactiveCard(inActive);
  }
}, [content, inactiveCard, auth.roles, auth.authenticated]);

  const uriFixed = heroContent?.field_hp_carousel_item_link?.uri?.replaceAll(
    "internal:/",
    ""
  );
  
  
  return (
    <ContentPageContainer className={"home"}>
      <section className="upper-section">
        <div className="hero">
          <div className={"hero-image-container"}>
            <picture className="hero-img">
              <source
                media="(min-width:768px)"
                srcSet={heroContent?.field_hero_image.url}
                type="image/webp"
              />
              <source
                media={"(min-width: 400px)"}
                srcSet={
                  heroContent?.field_hero_image?.url ||
                  heroContent?.field_hero_image.url
                }
                type={"image/png"}
              />
              <img
                src={
                  heroContent?.field_hero_image?.url ||
                  heroContent?.field_hero_image.url
                }
                alt={heroContent?.field_hero_image.alt}
                height="100%"
                width="100%"
              />
            </picture>
          </div>
          <div className="hero-content">
            <div className="hero-content-container">
              <h1 className={"site-title"}><span dangerouslySetInnerHTML={{ __html: heroContent?.title }} /></h1>
              <p
                dangerouslySetInnerHTML={{ __html: heroContent?.body || "" }}
              ></p>
              {heroContent?.field_hp_carousel_item_link &&
                heroContent.field_hp_carousel_item_link.title && (
                  <p className="cta-link-wrapper">
                    <RouterLink to={uriFixed}>
                      {heroContent.field_hp_carousel_item_link.title}
                    </RouterLink>
                  </p>
                )}
            </div>
          </div>
          {inactiveCard && (
            <LowerHomePageSection
              setHeroContent={setHeroContent}
              carouselCards={carouselCards}
              setCarouselCards={setCarouselCards}
              inactiveCard={inactiveCard}
              setInactiveCard={setInactiveCard}
            />
          )}
          <div className="left-side-linear-blur"></div>
        </div>
      </section>
      <DynamicFooter {...content?.footer_dynamic} />
    </ContentPageContainer>
  );
};
