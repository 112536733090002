/* eslint-disable no-useless-computed-key */
import { Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
//import React, { useEffect } from "react";
import { getRoute } from "../../routing/language_support";
import { Link, useParams } from "react-router-dom";
//import { Link as MuiLink } from '@material-ui/core';
import { LoginPage } from "../../interfaces/Pages";
import RegisterButton from "./RegisterButton"; 

import RegisterAsPatientImage from "../../resources/images/auth/register_patient_image.png";
//import RegisterAsHCPImage from "../../resources/images/auth/register_hcp_image.png";
import { useTranslation } from "react-i18next";
import "../../i18n/config";

//import { useDispatch } from 'react-redux';
//import { login } from '../../store/AuthActions';
import { useHistory } from 'react-router-dom';

export default function RegisterBoard(props: LoginPage) {
  const { userType } = useParams();
  const { t } = useTranslation();
  
  const hcpRegistrationAllowed = process.env.REACT_APP_SETTING_HCP_REGISTRATION_ALLOWED === 'true';
  const patientRegistrationAllowed = process.env.REACT_APP_SETTING_PATIENT_REGISTRATION_ALLOWED === 'true';
  
  const docCheckAllowed = process.env.REACT_APP_DOC_CHECK_ALLOWED === 'true'  
  const iqviaAllowed = process.env.REACT_APP_IQVIA_ALLOWED === 'true'
  const idacioAllowed = process.env.REACT_APP_IDACIO_ALLOWED === 'true'
  const tyenneAllowed = process.env.REACT_APP_TYENNE_ALLOWED === 'true'
  const otulfiAllowed = process.env.REACT_APP_OTULFI_ALLOWED === 'true'
  
  //const dispatch = useDispatch();
  const history = useHistory();
  let boardContent = "";

  if (userType === "hcp") {
    boardContent = t("register.form.common.content.hcpContent");
  } else if (userType === "patient") {
    boardContent = t("register.form.common.content.patientContent");
  } else {
    if (idacioAllowed && tyenneAllowed && otulfiAllowed) {
      boardContent = t("register.form.common.content.commonContent");
    } else if(idacioAllowed){
      boardContent = t("register.form.common.content.commonContentIdacio");
    } else if(tyenneAllowed){
      boardContent = t("register.form.common.content.commonContentTyenne");
    } else if(otulfiAllowed){
      boardContent = t("register.form.common.content.commonContentOtulfi");
    } else{
      boardContent = t("register.form.common.content.commonContent");
    }
  }
  
  const triggerHcaRegistration = async () => {
    if (iqviaAllowed) {
      history.push('/auth-loader?auth_type=one_key_register');
    }
  };

  return (
    <div className={"register-box"}>
      <div className="register-left-side">
        <Typography variant="h1">
          <Box mr={{ lg: 33, sm: 5 }} pb={1}>
            {t("register.form.common.pageTitle")}
          </Box>
        </Typography>
        <div className="register-card-content">
          <Typography variant="h6" component="div">
            <Box>
              <div
                dangerouslySetInnerHTML={{
                  __html: boardContent,
                }}
              ></div>
            </Box>
          </Typography>
        </div>
        <div className={"register-card-note"}>
          <Box mt={8} className={"foot-note"}>
            {t("register.form.common.sectionDescription")}
          </Box>
        </div>
      </div>
      <div className={"button-outer-container"}>
        <div className="register-buttons-container">
          {userType !== "hcp" && patientRegistrationAllowed && (
            <Grid item xs={12} sm={6} className={"button-grid"}>
              <Link
                to={getRoute('patientRegister')}
                variant="body2"
                underline={"none"}
                className={"patient-register-button register-button"}
              >
                <div
                  className={"button-content-container"}
                  aria-label={t("register.form.common.patientTile.label")}
                >
                  <img
                    src={RegisterAsPatientImage}
                    alt=""
                    className="button-image"
                  />
                  <div className="button-label">
                    {t("register.form.common.patientTile.label")}
                  </div>
                </div>
              </Link>
            </Grid>
          )}
          {userType !== "patient" && hcpRegistrationAllowed && (
            <Grid item xs={12} sm={6} className={"button-grid"}>
              <RegisterButton 
                docCheckAllowed={docCheckAllowed} 
                iqviaAllowed={iqviaAllowed} 
                onHcaRegister={triggerHcaRegistration}
              />                
            </Grid>
          )}
        </div>
      </div>
    </div>
  );
}
